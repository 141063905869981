import React, { useRef, useState } from "react";
import { useRouter } from "next/router";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import classNames from "classnames";
import CrossIcon from "../Icons/CrossIcon";
import styles from "./SearchBar.module.scss";
import SearchIcon from "@components/Icons/SearchIcon";

export function SearchBar({ value, small, style, onSearch }) {
    const t = GetTranslatedStrings();
    const router = useRouter();
    const searchBar = useRef(null);

    const [searchQuery, setSearchQuery] = useState(
        value ? value : router.query.q || ""
    );
    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            _handleSearch(e);
        }
    };

    const _handleSearch = (e) => {
        e.preventDefault();
        if (onSearch) {
            onSearch(searchQuery);
            return;
        }
        if (!searchQuery || searchQuery?.length == 0) {
            return;
        }
        router.push(`/?q=${searchQuery || ""}`);
    };

    const _handleClear = () => {
        setSearchQuery("");
        if (searchBar) {
            searchBar.current.focus();
        }
    };

    return (
        <>
            <div className={styles.container} style={{ ...style }}>
                <input
                    ref={searchBar}
                    autoFocus={!small}
                    enterKeyHint="search"
                    autoCapitalize="off"
                    placeholder={t.searchPlaceholder}
                    value={searchQuery}
                    onKeyDown={_handleKeyDown}
                    onChange={(event) => {
                        setSearchQuery(event.target.value);
                    }}
                    className={classNames({ [styles.small]: small })}
                />
                <div className={styles.accessoriesContainer}>
                    {small && searchQuery?.length != 0 && (
                        <CrossIcon
                            className={styles.clearIcon}
                            onClick={_handleClear}
                        />
                    )}

                    <button
                        type="submit"
                        className={classNames({ [styles.small]: small })}
                        onClick={_handleSearch}
                    >
                        <SearchIcon className={styles.searchIcon} />
                    </button>
                </div>
            </div>
        </>
    );
}
