import React from "react";
import Page from "../../Layout/page";
import { ErrorPage } from "../ErrorPage/ErrorPage";

export default function CrashPage() {
    return (
        <Page fluid bodyClassName="error-page">
            <ErrorPage
                title="ÚPS!"
                subtitle="Þetta er skrýtið... Við erum að skoða þetta!"
                image="/img/trash-can-500.svg"
            />
        </Page>
    );
}
