import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import FlexContainer from "@components/FlexContainer/FlexContainer";

export const buttonSize = {
    small: "sm",
    large: "lg",
};

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.string,
    isBlock: PropTypes.bool,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    size: PropTypes.string,
};

export function Button({
    text,
    onClick,
    type = "primary",
    icon,
    color,
    isBlock,
    isDisabled,
    size,
    isLoading,
    className,
    ...props
}) {
    return (
        <button
            {...props}
            disabled={isDisabled || isLoading}
            style={{
                backgroundColor: color,
                borderWidth: 0,
                borderRadius: 50,
                paddingTop: 0,
                paddingBottom: 0,
                ...props.style,
            }}
            onClick={() => onClick && onClick()}
            className={classNames("btn", className, {
                [`btn-${type}`]: type,
                [`btn-${size}`]: size,
                "btn-block": isBlock,
                "btn-disabled": isDisabled,
            })}
        >
            {isLoading ? (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{
                        height: props?.style?.height || 40,
                        position: "relative",
                    }}
                >
                    <ReactLoading
                        type={"bubbles"}
                        color={"white"}
                        width="100px"
                        height="100px"
                    />
                </FlexContainer>
            ) : (
                <>
                    {icon && <FontAwesomeIcon icon={icon} />}
                    {icon && text && <span style={{ marginLeft: "10px" }} />}
                    {text}
                </>
            )}
        </button>
    );
}
