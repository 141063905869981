import * as Routes from "./routes";
import * as Roles from "@config/sidebars/innra_routes/roles";

export default function MenuBuilder(user) {
    let menu = [{ ...Routes.ADGANGUR }, { ...Routes.UTSKRA }];

    if (user?.user_type === Roles.SUPER_ADMIN) {
        menu.splice(1, 0, ...[Routes.SKRANING, Routes.LEAF_SYSTEM]);
    }

    return menu;
}
