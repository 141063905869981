export default {
    //==========================================================
    // FRONT PAGE
    laufid_customers: "Viðskiptavinir í Laufinu", /// NEEDS TRANSLATION

    //==========================================================
    // HEADER
    register_your_company: "Zarejestruj swoją firmę na bezpłatny okres próbny",

    //==========================================================
    // SIDE BAR
    home: "Strona główna",
    login: "Logowanie",
    software: "Hugbúnaðurinn", /// NEEDS TRANSLATION
    faq: "FAQ", /// NEEDS TRANSLATION
    try_free: "Try Free", /// NEEDS TRANSLATION
    stories: "Historie",
    graph: "Porównanie",
    certificates: "Certyfikaty",
    about_us: "O nas",
    information_page: "Strona informacyjna",
    laufid_guide: "Droga do Laufið",
    pricelist: "Verðskrá", /// NEEDS TRANSLATION
    contact_us: "Hafðu samband", /// NEEDS TRANSLATION

    //==========================================================
    // Cookie Consent
    cookie_consent:
        "Þessi vefsíða notar vafrakökur (e. Cookies) til að auka vafraupplifun þína",
    cookie_consent_button: "Ég skil",
    cookie_consent_link: "Sjá skilmála hér.",

    //==========================================================
    // Opening Hours: Godziny otwarcia
    monday: "Poniedziałek",
    tuesday: "Wtorek",
    wednesday: "Środa",
    thursday: "Czwartek",
    friday: "Piątek",
    saturday: "Sobota",
    sunday: "Niedziela",

    //==========================================================
    // Search
    searchPlaceholder: "Czego szukasz?",
    searchButton: "Szukaj",

    search_results: "Wyniki wyszukiwania",
    search_results_for: "Wyniki wyszukiwania dla",

    nothing_found_with: " Nic nie znaleziono ze słowem kluczowym",
    nothing_found_using: "Używanie",

    companies: "Firmy",
    individuals: "Osoby fizyczne",

    load_more_companies: "Dodaj więcej firm",
    load_more_individuals: "Load More Individuals", /// NEEDS TRANSLATION

    no_products_found_for: "Nie znaleziono towarów ze słowem kluczowym",
    no_results_for: "Nie znaleziono wyników dla",

    not_finding_what_you_are_looking_for: "Nie znalazłeś tego, czego szukasz?",
    we_can_help: "Możemy Ci pomóc jeżeli zadzwonisz pod numer 1819",
    call_1819: "Zadzwoń pod numer 1819",
    have_a_suggestion: "Czy masz dla nas komentarz?",
    here_you_can_send_us: "Tutaj możesz wysłać nam komentarz",
    send_us_a_message: "Wyślij nam komentarz",
    you_know_where_to_look: "Wyszukiwanie",

    search_result_list: "Lista",
    search_result_graph: "Porównanie",
    search_result_products: "Towary",

    open: "Otwarte",
    closed: "Zamknięte",

    //==========================================================
    // ADS
    ad: "REKLAMA",

    //==========================================================
    // SUSTAINABILITY
    sdg: "Globalne Cele ONZ",
    esg: "Środowisko, Społeczeństwo, Zarządzanie",

    //==========================================================
    // INFO DETAILS
    info_details_sustainability: "Zrównoważony rozwój",
    info_details_carbon_neutrality: "Wpływ na klimat",
    info_details_opening_hours: "Godziny otwarcia",
    info_details_phone_number_singular: "Numer telefonu",
    info_details_phone_number_plural: "Numery telefonów",
    info_details_website_singular: "Strona internetowa",
    info_details_website_plural: "Strony internetowe",
    info_details_email_singular: "E-mail",
    info_details_email_plural: "E-maile",
    info_details_kennitala: "Kennitala",
    info_details_fyrirtaekjaskra: "Rejestracja firm",
    info_details_social_media: "Media społecznościowe",
    info_details_map: "Mapa",
    info_details_tags: "Tagi",
    info_details_brands: "Znaki towarowe",
    info_details_services: "Usługi",
    info_details_other_listings: "Inne rejestracje",
    info_details_employees: "Personel",
    info_details_navigate: "Przeglądanie",
    info_details_more_info: "Więcej informacji",
    info_details_kennitala_abbr: "Kt.",

    //==========================================================
    // Breadcrumbs
    front_page: "Strona tytułowa",

    //==========================================================
    // MAP
    map_search_on_map: "Szukaj na mapie",

    //==========================================================
    // OPENING HOURS
    opening_hours_open_until: "Otwarte do",
    opening_hours_open_until_midnight: "Otwarte do północy",
    opening_hours_opens_at: "Otwarte o",
    opening_hours_closed: "Zamknięte",
    opening_hours_always_open: "Zawsze otwarte",

    //==========================================================
    // CERTIFICATIONS
    more_details_link: "Więcej informacji",

    //==========================================================
    // GRAPH
    graph_companies: "Firmy",
    graph_gray_zone: "Szara strefa",
    graph_green_zone: "Zielona strefa",
    graph_leaves: "Liście",
    graph_pluses: "Zielone kroki",

    //==========================================================
    // ABOUT US
    about_us_title: "O nas",
    about_us_section:
        "Pomysł na platformę cyfrową Laufið narodził się pod koniec 2020 roku i od tamtej pory rozwija się i prosperuje. Dołożyliśmy wszelkich starań, aby stworzyć towary i usługi zaprojektowane w taki sposób, aby miały wpływ na społeczeństwo. W rozwój platformy włożono wiele pracy przy udziale wielu firm, pracując w grupach fokusowych; profesjonalnej pomocy ekspertów w kwestiach środowiskowych i zrównoważonego rozwoju; nie wspominając o całym utalentowanym personelu, który przyczynił się do jej rozwoju. Duży nacisk kładziemy na wsłuchiwanie się w głos przyszłych klientów i użytkowników oraz najlepszych ekspertów Laufið.",

    our_history_title: "Nasza historia",
    our_history_section:
        "Dwie dobrze ugruntowane firmy: 1819 oraz Zenter ehf., mają zaszczyt być właścicielami Laufið. Firma 1819 została założona w 2014 roku i jest firmą informacyjno-usługową, której celem jest pomagać osobom fizycznym i firmom w dostarczaniu informacji oraz zwiększaniu ich widoczności. Natomiast Zenter ehf. to firma programistyczna, która od 2010 roku tworzy i obsługuje oprogramowanie dla firm, obejmujące m.in. e-maile, SMS, CRM oraz oprogramowania umożliwiające sprzedaż. Firma 1819 oraz Zenter ehf., wspólnie kładą podwaliny pod Laufið.",

    services_companies_title: "Usługi dla firm i ich menedżerów",
    services_companies_section1:
        "Laufið jest platformą cyfrową, na której menedżerowie islandzkich firm mogą połączyć się i korzystać ze zróżnicowanych narzędzi w drodze do bardziej zrównoważonych działań biznesowych, działań związanych z odpowiedzialnością społeczną oraz w walce ze zmianami klimatycznymi.",

    services_companies_section2:
        "Po rozmowach z menedżerami małych i średnich firm w Islandii stało się jasne, że firmom brakuje praktycznych narzędzi do zarządzania i uczestniczenia w działaniach promujących bardziej zrównoważone działania biznesowe. Zestaw narzędzi i system motywacyjny Laufið stara się odpowiedzieć na wezwanie menedżerów, przyczyniając się do rozwoju firm w prostych, ale ważnych działaniach. Działania te zmniejszają wpływ firm na środowisko, pomagają promować bardziej odpowiedzialne praktyki oraz tworzyć bardziej zrównoważone społeczeństwo.",

    services_individuals_title: "Obsługa konsumentów",
    services_individuals_section1:
        "Laufíð jest pierwszym islandzkim dostawcą informacji o zielonym środowisku. Celem firmy jest wzmocnienie pozycji konsumentów i umożliwienie im podejmowania świadomych decyzji dotyczących ich konsumpcji w oparciu o wyniki firm w zakresie zrównoważonego rozwoju i odpowiedzialności społecznej.",

    services_individuals_section2:
        "Na stronie internetowej Laufið można wyszukiwać firmy, towary oraz usługi i dokładnie sprawdzać w jaki sposób firmy działają w kwestiach ochrony środowiska i zrównoważonego rozwoju. Można też porównywać wyniki firm, poszukiwać certyfikowanych towarów i podejmować świadome decyzje dotyczące tego, jakie towary i usługi wybrać i w jakich firmach robić zakupy.",

    audlindatorg_title: "Auðlindatorg",
    audlindatorg_section:
        "Auðlindatorg to rynek, na którym handluje się wyłącznie używanymi towarami. Auðlindatorg ma na celu ułatwienie firmom wspierania idei gospodarki o obiegu zamkniętym i zachęcać firmy do zaprzestania myślenia o jednorazowych rzeczach. Auðlindatorg będzie dostępny zarówno na stronie internetowej jak i w aplikacji , gdzie w prosty sposób będzie można sprzedawać, kupować, wynajmować, pożyczać lub przekazywać towary. Auðlindatorg będzie dostępny dla każdego.",

    umhverfismaelabord_title: "Panel środowiskowy",
    umhverfismaelabord_section:
        "Panel środowiskowy jest jednym z narzędzi Laufið i częściowo naszym wkładem w osiągnięcie celów Porozumienia paryskiego. Cel panela jest również odpowiedzią na potrzebę stworzenia i zarządzania największym źródłem danych dotyczących kwestii środowiskowych i klimatycznych w Islandii. Głównym celem dashboardu środowiskowego jest ułatwienie rządowi, ministrom, samorządom oraz innym kluczowym interesariuszom dostępu do danych, które pokazują stan rzeczy w czasie rzeczywistym, tj. kwestie odpadów, emisji i sekwestracji dwutlenku węgla, kwestie klimatyczne, kwestie energetyczne, kwestie transportowe, stan budownictwa oraz stan samochodów i innych pojazdów.",
};
