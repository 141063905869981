import React from "react";
import classNames from "classnames";

const BorderedContainer = (props) => {
    const { children, className } = props;
    return (
        <div
            {...props}
            className={classNames(
                "bordered-container",
                "laufid-background-secondary",
                className
            )}
        >
            {children}
        </div>
    );
};
export default BorderedContainer;
