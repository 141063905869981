export const WEBPAGE_TITLE = "Laufið";
export const API_ENDPOINT =
    process.env.LAUFID_ENDPOINT || "https://api.laufid.is/api/";
export const API_1819_ENDPOINT =
    process.env.OLD_1819_ENDPOINT || "https://old.1819.is/api/";
export const APP_KEY_LAUFID = process.env.APP_KEY_LAUFID;
export const GOOGLE_GA_MEASUREMENT_ID = process.env.GA_MEASUREMENT_ID;
export const GOOGLE_GA_TRACKING_ID = process.env.GA_TRACKING_ID;
export const THEME_COLOR = "#009448";
export const MITT_MAX_CONTENT_WIDTH = 1150;
export const ISLAND_ID = process.env.ISLAND_ID
    ? process.env.ISLAND_ID
    : "1819.is";
