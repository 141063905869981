import React from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useRouter } from "next/router";

export default function MotionTransition({ children }) {
    const router = useRouter();
    return (
        <AnimateSharedLayout>
            <AnimatePresence>
                <motion.div
                    transition={{ type: "switch", duration: 0.3 }}
                    initial={{ x: 15, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    key={router.route}
                >
                    {children}
                </motion.div>
            </AnimatePresence>
        </AnimateSharedLayout>
    );
}
