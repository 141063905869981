import React from "react";
import CookieConsent from "react-cookie-consent";
import Link from "next/link";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";

export default function LaufidCookieConsent({ visible }) {
    const t = GetTranslatedStrings();
    return (
        <CookieConsent
            buttonText={t.cookie_consent_button}
            disableStyles
            containerClasses="container cookie-container"
            buttonClasses="cookie-button"
            contentClasses="cookie-content"
            visible={visible}
        >
            {t.cookie_consent}
            <Link
                href="/doc/personuvernd.pdf"
                className="cookie-link"
                target="_blank"
            >
                {t.cookie_consent_link}
            </Link>
        </CookieConsent>
    );
}
