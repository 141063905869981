import React from "react";

export default function FlexContainer(props) {
    const { children, justifyContent, alignItems, column } = props;
    return (
        <div
            {...props}
            style={{
                display: "flex",
                flexDirection: column ? "column" : undefined,
                justifyContent: justifyContent,
                alignItems: alignItems,
                ...props.style,
            }}
        >
            {children}
        </div>
    );
}
