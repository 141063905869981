import React, { useEffect } from "react";
import authenticated_route from "@navigation/authenticated_route";
import Page from "@components/Layout/page";
import SideBar from "@components/side_bar";
import useUser from "@hooks/use_user";
import MenuBuilder from "@config/sidebars/innra_routes/MenuBuilder";
import FlexContainer from "./FlexContainer/FlexContainer";
import { ContentContainer } from "./ContentContainer/ContentContainer";

function InnraLayout({ children }) {
    const user = useUser();
    const [menu, setMenu] = React.useState([]);
    useEffect(() => {
        setMenu(MenuBuilder(user));
    }, [user]);

    return (
        <Page fluid={true} showsSearchBar showsFooter={false}>
            <ContentContainer>
                <FlexContainer className="innra-layout-container">
                    <SideBar menu={menu} collapseInMobile />
                    <FlexContainer
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            maxWidth: "100vw",
                        }}
                    >
                        <div style={{ flex: 1, minWidth: 0 }}>{children}</div>
                    </FlexContainer>
                </FlexContainer>
            </ContentContainer>
        </Page>
    );
}
export default authenticated_route(InnraLayout, {
    pathAfterAccessFailure: "/mitt/notandi",
    pathAfterLoginFailure: "/innskraning",
});
