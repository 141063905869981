import React from "react";
import Link from "next/link";
import LaufidLogo from "@components/Icons/LaufidLogo/LaufidLogo";
import { THEME_COLOR } from "@utils/Constants";
import { Leaf } from "@laufid/components";
import styles from "./HeaderLogo.module.scss";
import classNames from "classnames";

const HeaderLogo = ({ small }) => (
    <Link className={styles.container} href="/" style={{}}>
        <div>
            <Leaf
                plain
                className={classNames(styles.leaf, { [styles.small]: small })}
                color={THEME_COLOR}
            />

            <LaufidLogo
                alt="Laufið logo"
                className={classNames(styles.laufid, { [styles.small]: small })}
            />
        </div>
    </Link>
);
export default HeaderLogo;
