import { Button } from "@laufid/components";
import React from "react";
import styles from "./ErrorPage.module.scss";

export const ErrorPage = ({ title, subtitle, image }) => {
    return (
        <div className={styles.container}>
            <h1>{title}</h1>
            <h5>{subtitle}</h5>
            <img src={image} />
            <Button href="/">Fara á forsíðu</Button>
        </div>
    );
};
