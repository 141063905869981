import localFont from "next/font/local";

const AvenirNext = localFont({
    src: [
        {
            path: "./AvenirNextLTPro-UltLt.woff2",
            weight: "200",
            style: "normal",
        },
        {
            path: "./AvenirNextLTPro-Regular.woff2",
            weight: "400",
            style: "normal",
        },
        {
            path: "./AvenirNextLTPro-Medium.woff2",
            weight: "500",
            style: "normal",
        },
        {
            path: "./AvenirNextLTPro-Demi.woff2",
            weight: "600",
            style: "normal",
        },
        {
            path: "./AvenirNextLTPro-Bold.woff2",
            weight: "700",
            style: "normal",
        },
        {
            path: "./AvenirNextLTPro-Heavy.woff2",
            weight: "900",
            style: "normal",
        },
    ],
});

export default AvenirNext;
