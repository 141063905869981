import * as React from "react";

const SearchIcon = (props) => (
    <svg
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M25.96 24.077a14.285 14.285 0 1 0-1.652 1.654l9.579 9.582a1.17 1.17 0 0 0 2.009-.823 1.17 1.17 0 0 0-.356-.83l-9.58-9.583ZM6.648 23.29a11.932 11.932 0 1 1 16.875 0 11.953 11.953 0 0 1-16.875 0Z"
            fill="#009448"
        />
    </svg>
);

export default SearchIcon;
