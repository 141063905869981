import React from "react";
import Link from "next/link";
import HeaderLogo from "@components/HeaderLogo/HeaderLogo";
import { Title } from "@components/ui";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import { SearchBar } from "@components/SearchBar/SearchBar";
import classNames from "classnames";
import styles from "./Header.module.scss";
import { ContentContainer } from "@components/ContentContainer/ContentContainer";
import { Button } from "@laufid/components";

export const Header = ({ showsSearchBar }) => {
    const t = GetTranslatedStrings();
    return (
        <header className={styles.container}>
            <ContentContainer className={styles.contentContainer}>
                <HeaderLogo small={showsSearchBar} />

                {showsSearchBar ? (
                    <SearchBar
                        small
                        style={{ marginLeft: 15, flexShrink: 1 }}
                    />
                ) : (
                    <div style={{ flex: 1 }}></div>
                )}

                <nav style={{ height: 65, flexShrink: 0 }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexGrow: 1,
                        }}
                    >
                        <ul style={{ display: "flex" }}>
                            <div className={styles.headerAccessories}>
                                <HeaderMenuItem
                                    fontColor="#515151"
                                    text={t.software}
                                    link="/hugbunadurinn"
                                />
                                {/* <HeaderMenuItem
                                        fontColor="#515151"
                                        text={t.faq}
                                        link="/faq"
                                    /> */}
                                <HeaderMenuItem
                                    fontColor="#515151"
                                    text={t.about_us}
                                    link="/um-okkur"
                                />
                            </div>
                            <HeaderMenuItem
                                className={classNames(styles.loginButton, {
                                    [styles.showsSearchBar]: showsSearchBar,
                                })}
                                text={t.login}
                                link="https://admin.laufid.is"
                            />
                        </ul>
                        <div
                            className={classNames(styles.tryFreeButton, {
                                [styles.showsSearchBar]: showsSearchBar,
                            })}
                        >
                            <Button
                                size={"medium"}
                                href="https://admin.laufid.is/registration"
                                style={{
                                    fontWeight: 600,
                                    alignSelf: "center",
                                    whiteSpace: "nowrap",
                                    padding: "7px 20px",
                                }}
                            >
                                {t.try_free}
                            </Button>
                        </div>
                    </div>
                </nav>
            </ContentContainer>
        </header>
    );
};

function HeaderMenuItem({ text, inverted, fontColor, className, ...props }) {
    return (
        <li
            className={classNames(
                styles.menuItemText,
                {
                    [styles.inverted]: inverted,
                },
                className
            )}
        >
            <Link href={props.link ? props.link : "#"}>
                <Title size={5} style={{ color: fontColor }}>
                    {text}
                </Title>
            </Link>
        </li>
    );
}
