import { THEME_COLOR } from "@utils/Constants";
import * as React from "react";

export const Wave = ({ color = THEME_COLOR, placement = "top", ...props }) => {
    let viewBox = "";
    if (placement == "bottom") {
        viewBox = "0 930 1920 999";
    } else {
        viewBox = "0 0 1920 97";
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                height: "auto",
                maxHeight: 30,
                marginBottom: "min(-0.07vw, -0.4px)",
                zIndex: -1,
            }}
        >
            <svg
                viewBox={viewBox}
                fill="none"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    width: "100%",
                    height:
                        (placement === "top" && "30px") ||
                        (placement === "bottom" && "430px"),
                }}
                {...props}
            >
                <path
                    d="M1981.67 933.524c-27.41 47.79-92.95 60.912-148.51 53.325-55.57-7.587-108.42-30.422-164.39-34.311-102.19-7.1-265.43 34.819-367.4 44.5-163.47 15.522-252.91-75.218-416.3-58.9-107.086 10.694-199.255 45.064-306.853 48.131-95.463 2.721-190.385-13.452-283.335-34.87-119.139-27.455-248.145 45.893-369.604 30.952-24.106-2.966-44.6-70.647-64.79-83.839-43.181-28.214-51.224-85.966-54.334-136.6a4392.589 4392.589 0 0 1-1.9-505.458c3.1-57.564 12.815-201.233 57.832-238.538 81.118-67.222 201.4 83.3 307.452 74.9C282.146 83.898 382.256-17.628 491.865 9.101c48.555 11.84 87.794 46.688 134.628 63.908C798.875 136.392 895.287 2.67 1036.37 2.426c116.73-.2 221.79 79.514 338.52 79.73 105.32.2 200.24-64.2 304.9-75.633 97.8-10.682 193.88 25.569 285.29 61.15 28.69 11.166 58.49 23.083 78.88 45.725 23.82 26.457 30.91 63.15 35.92 98.036a1594.296 1594.296 0 0 1-58.24 705.572"
                    fill={color}
                />
            </svg>
        </div>
    );
};
