import classNames from "classnames";
import React from "react";
import styles from "./ContentContainer.module.scss";

export const ContentContainer = ({ children, ...props }) => {
    return (
        <div className={styles.parentContainer}>
            <div
                {...props}
                className={classNames(styles.container, props.className)}
            >
                {children}
            </div>
        </div>
    );
};
