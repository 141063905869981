class Route {
    constructor({
        title,
        link,
        icon,
        isExternal = false,
        sub_pages,
        default_open,
    }) {
        this.title = title;
        this.link = link;
        this.icon = icon;
        this.isExternal = isExternal;
        this.sub_pages = sub_pages;
        this.default_open = default_open;
    }
    addSubPages(...pages) {
        this.sub_pages = pages;
        return this;
    }
}

export const ADGANGUR = new Route({
    title: "Minn aðgangur",
    link: "/mitt/notandi",
    icon: "/img/sidebar/user.png",
});

export const SKRANING = new Route({
    title: "Skráning hjá Laufinu",
    link: "/mitt/skraning",
    icon: "/img/sidebar/registration.png",
});

export const LEAF_SYSTEM = new Route({
    title: "Laufakerfið",
    link: "https://admin.laufid.is",
    icon: "/img/leaf.svg",
    isExternal: true,
});

export const UTSKRA = new Route({
    title: "Útskrá",
    title_en: "Log Out",
    link: "/logout",
    icon: "/img/sidebar/log-out.png",
});

export const THJONUSTUVER = new Route({
    title: "Þjónustuver",
    default_open: true,
    icon: "/img/sidebar/call-center.png",
});

export const THJONUSTUSIMI = new Route({
    title: "Þjónustusími",
    link: "/mitt/simi/simsvorun",
    icon: "/img/sidebar/incoming-call.png",
    isExternal: true,
});

export const UTHRINGIVER = new Route({
    title: "Úthringiver",
    link: "/mitt/simi/uthringiver",
    icon: "/img/sidebar/outgoing.png",
    isExternal: true,
});

export const THJODSKRA = new Route({
    title: "Þjóðskrá",
    link: "/mitt/thjonustuver/thjodskra",
    icon: "/img/sidebar/contact-list.png",
});

export const VIDVERULISTI = new Route({
    title: "Viðverulisti",
    link: "/mitt/thjonustuver/vidverulisti",
    icon: "/img/sidebar/verified-account.png",
});

export const VERKEFNI = new Route({
    title: "Verkefni",
    link: "/mitt/verkefni",
    icon: "/img/sidebar/controller.png",
});

export const SKRAR = new Route({
    title: "Skrár",
    link: "/mitt/skrar",
    icon: "/img/sidebar/folder.png",
});

export const FYRIRTAEKI = new Route({
    title: "Fyrirtæki",
    icon: "/img/sidebar/companies.png",
});

export const LEITARORD = new Route({
    title: "Leitarorð",
    link: "/mitt/fyrirtaeki/leitarord",
});

export const LISTAVINNSLA = new Route({
    title: "Listavinnsla",
    link: "/mitt/fyrirtaeki/listavinnsla",
});

export const NYTT_HEIMILISFANG = new Route({
    title: "Nýtt heimilisfang",
    link: "/mitt/fyrirtaeki/nytt-heimilisfang",
});

export const NYSKRANINGAR = new Route({
    title: "Nýskráningar",
    link: "/mitt/fyrirtaeki/nyskraningar",
});

export const SAMNINGAR = new Route({
    title: "Samningar",
    link: "/mitt/fyrirtaeki/samningar",
});

export const HLUTFOLL = new Route({
    title: "Hlutföll",
    link: "/mitt/thjonustuver/hlutfoll",
    icon: "/img/sidebar/ratio.png",
});

export const GROF = new Route({
    title: "Gröf",
    link: "/mitt/thjonustuver/grof",
    icon: "/img/sidebar/bar-chart.png",
});

export const SIMSVORUN_FYRIRTAEKJA = new Route({
    title: "Fyrirtæki í símsvörun",
    link: "/mitt/thjonustuver/simsvorun",
    icon: "/img/sidebar/script.png",
});

export const SMS_GATT = new Route({
    title: "SMS gátt",
    link: "/mitt/sms",
    icon: "/img/sidebar/sms.png",
});

export const SMS_GATT_PARENT = new Route({
    title: "SMS gátt",
    icon: "/img/sidebar/sms.png",
    default_open: true,
});

export const SMS_GATT_NOTENDUR = new Route({
    title: "Notendur",
    link: "/mitt/sms/notendur",
    icon: "/img/sidebar/sms.png",
});

export const MARKADSMAL = new Route({
    title: "Markaðsmál",
    icon: "/img/sidebar/marketing.png",
});

export const AUGLYSINGAR = new Route({
    title: "Auglýsingar",
    link: "/mitt/markadsmal/auglysingar",
});

export const PAKKAR = new Route({
    title: "Pakkar",
    link: "/mitt/markadsmal/pakkar",
});

export const SKRIFSTOFA = new Route({
    title: "Skrifstofa",
    default_open: true,
    icon: "/img/sidebar/office.png",
});

export const SVAR = new Route({
    title: "Svar",
    link: "/mitt/skrifstofa/svar",
    icon: "/img/sidebar/svar.png",
});

export const GJALDKERI = new Route({
    title: "Gjaldkeri",
    link: "/mitt/skrifstofa/gjaldkeri",
});

export const REIKNINGAR_SMS = new Route({
    title: "Reikningar SMS",
    link: "/mitt/skrifstofa/reikningar/sms",
    icon: "/img/sidebar/sms-bill.png",
});

export const REIKNINGAR_API = new Route({
    title: "Reikningar API",
    link: "/mitt/skrifstofa/reikningar/api",
    icon: "/img/sidebar/api-bill.png",
});

export const REIKNINGAR_SVARA = new Route({
    title: "Reikningar Svörun",
    link: "/mitt/skrifstofa/reikningar/svara",
    icon: "/img/sidebar/invoice.png",
});

export const TOLFRAEDI = new Route({
    title: "Tölfræði",
    link: "/mitt/skrifstofa/tolfraedi",
    icon: "/img/sidebar/bar-chart.png",
});

export const REIKNINGAR_THJONUSTUVER = new Route({
    title: "Reikningar Þjónustuver",
    link: "/mitt/skrifstofa/reikningar/thjonustuver",
    icon: "/img/sidebar/bill.png",
});

export const REIKNINGAR_HRINGJA_UT = new Route({
    title: "Reikningar Hringja út",
    link: "/mitt/skrifstofa/reikningar/hringja-ut",
});

export const SIMANUMERAFYRIRSPURN = new Route({
    title: "Símanúmerafyrirspurn",
    link: "/mitt/skrifstofa/simanumerafyrirspurn",
});

export const SVORUNARYFIRLIT = new Route({
    title: "Svörunaryfirlit",
    link: "/mitt/skrifstofa/svorunaryfirlit",
});

export const ENDURNYJANIR = new Route({
    title: "Endurnýjanir",
    link: "/mitt/skrifstofa/endurnyjanir",
});

export const STILLINGAR = new Route({
    title: "Stillingar",
    default_open: true,

    icon: "/img/sidebar/settings.png",
});

export const ADGANGSSTYRING = new Route({
    title: "Aðgangsstýring",
    icon: "/img/sidebar/access-control.png",
});

export const HLUTVERK = new Route({
    title: "Hlutverk",
    icon: "/img/sidebar/role.png",
    link: "/mitt/stillingar/hlutverk",
});
export const API_NOTENDUR = new Route({
    title: "API notendur",
    icon: "/img/sidebar/role.png",
    link: "/mitt/stillingar/api-notendur",
});

export const POSTNUMER = new Route({
    title: "Póstnúmer",
    link: "/mitt/stillingar/postnumer",
    icon: "/img/sidebar/mailbox.png",
});

export const STARFSFOLK = new Route({
    title: "Starfsfólk",
    link: "/mitt/stillingar/starfsfolk",
    icon: "/img/sidebar/employees.png",
});

export const SMS_NOTENDUR = new Route({
    title: "SMS notendur",
    link: "/mitt/sms/notendur",
    icon: "/img/sidebar/sms-user.png",
});

export const STARFSMANNAHANDBOK = new Route({
    title: "Starfsmannahandbók",
    link: "/mitt/starfsmannahandbok",
    icon: "/img/sidebar/book.png",
});

export const HAGNYTAR_UPPLYSINGAR = new Route({
    title: "Hagnýtar upplýsingar",
    link: "/mitt/hagnytar-upplysingar",
    icon: "/img/sidebar/book.png",
});

export const SIMTOL = new Route({
    title: "Símtöl",
    link: "/mitt/skrifstofa/simtol",
    icon: "/img/sidebar/phone-call.png",
});
