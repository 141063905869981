import { fetcherNew1819 } from "@utils/FetcherNew1819";
import { localStorageUtil } from "@utils/storage";
import jwt from "jsonwebtoken";
import Router from "next/router";

export const types = {
    user_logged_in: "user_logged_in",
    user_logged_out: "user_logged_out",
    login_error: "login_error",
    set_tokens: "set_tokens",
    refresh_access_token: "refresh_access_token",
    get_user: "get_user",
};

export const setAccessToken = (access_token) => (dispatch) => {
    var jwtPayload = jwt.verify(
        access_token,
        process.env.ACCESS_TOKEN_PUBLIC_KEY,
        {
            algorithms: ["RS256"],
        }
    );
    dispatch({
        type: types.user_logged_in,
        payload: { user: jwtPayload, access_token },
    });

    localStorageUtil.setItem("user", jwtPayload);
    localStorageUtil.setItem("access_token", access_token);
    dispatch(getUser());
};

export const refreshAccessToken = () => (dispatch) => {
    fetcherNew1819("token", "POST")
        .then((data) => {
            dispatch(setAccessToken(data.access_token));
        })
        .catch((e) => {
            dispatch({ type: types.login_error, payload: e.message });
        });
};

export const getUser = () => async (dispatch, getState) => {
    if (!getState()?.auth?.user) {
        return;
    }
    try {
        var user = await fetcherNew1819("user");
        dispatch({ type: types.get_user, payload: user });
        localStorageUtil.setItem("user", user);
    } catch (e) {
        if (e.statusCode == 401) {
            dispatch(refreshAccessToken());
        }
        return;
    }
};

export const logUserOut = () => (dispatch, getState) => {
    if (!getState()?.auth?.user) {
        return;
    }
    fetcherNew1819("logout", "POST").finally(() => {
        dispatch({ type: types.user_logged_out, payload: null });
        localStorageUtil.removeItem("user");
        localStorageUtil.removeItem("access_token");
    });
    Router.push("/");
};

export const loginExpired = () => (dispatch, getState) => {
    if (!getState()?.auth?.user) {
        return;
    }
    dispatch({ type: types.user_logged_out, payload: null });
    localStorageUtil.removeItem("user");
    localStorageUtil.removeItem("access_token");
    if (Router.pathname.startsWith("/mitt")) {
        Router.push("/");
    }
};
