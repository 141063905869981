import { useRouter } from "next/router";
import is from "@locales/is/translation";
import en from "@locales/en/translation";
import pl from "@locales/pl/translation";

function GetTranslatedStrings() {
    const locale = useRouter()?.locale;
    switch (locale) {
        case "is":
            return is;
        case "en":
            return en;
        case "pl":
            return pl;
        default:
            return is;
    }
}

export default GetTranslatedStrings;
