import React from "react";
import { Wave } from "@components/Wave/Wave";
import styles from "./Footer.module.scss";
import Link from "next/link";

const socials = [
    {
        icon: "/img/footer/linkedin.svg",
        url: "https://www.linkedin.com/company/laufid/",
    },
    {
        icon: "/img/footer/instagram.svg",
        url: "https://www.instagram.com/laufid.is/",
    },
    {
        icon: "/img/footer/facebook.svg",
        url: "https://www.facebook.com/laufidiceland/",
    },
    {
        icon: "/img/footer/spotify.svg",
        url: "https://open.spotify.com/show/32Nx41Hk4V4JYI8bnvTRYr",
    },
];

function Footer() {
    const infoText = "";
    return (
        <>
            <Wave
                placement="top"
                style={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                    maxHeight: 30,
                    zIndex: -1,
                    paddingTop: 1,
                }}
            />
            <footer className={styles.container}>
                <nav className={styles.contentContainer}>
                    <FooterNavigationSection
                        title="Hugbúnaðurinn"
                        items={[
                            { title: "Um hugbúnaðinn", link: "/hugbunadurinn" },
                            { title: "Verðskrá", link: "/verdskra" },
                        ]}
                    />
                    {/* <FooterNavigationSection
                        title="FAQ"
                        items={[{ title: "Spurningar og svör", link: "/faq" }]}
                    /> */}
                    <FooterNavigationSection
                        title="Um Laufið"
                        items={[
                            { title: "Sagan okkar", link: "/um-okkur" },
                            { title: "Hafðu samband", link: "/hafa-samband" },
                            {
                                title: "Persónuverndarstefna",
                                link: "/doc/personuvernd.pdf",
                                external: true,
                            },
                        ]}
                    />
                    <FooterNavigationSection />
                </nav>
                <div className={styles.separator} />
                <div className={styles.footerBottom}>
                    <p className={styles.copyright}>
                        {`© ${new Date().getFullYear()} Laufin ehf. - Akralind 6 - 201 Kópavogur - `}
                        <a href="mailto:laufid@laufid.is">laufid@laufid.is</a> -
                        S: <a href="tel:5500090">550 0090</a>
                    </p>
                    <div className={styles.socialContainer}>
                        {socials.map((social, index) => {
                            return (
                                <a
                                    key={index}
                                    href={social.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className={styles.socialIcon}
                                        src={social.icon}
                                    />
                                </a>
                            );
                        })}
                    </div>
                </div>
            </footer>
        </>
    );
}

function FooterNavigationSection({ title, items }) {
    return (
        <div>
            <h5>{title}</h5>
            <ul>
                {items?.map?.((item, index) => {
                    return (
                        <Link
                            key={index}
                            href={item.link}
                            target={item.external ? "_blank" : undefined}
                        >
                            <li key={item.title}>{item.title}</li>
                        </Link>
                    );
                })}
            </ul>
        </div>
    );
}

export default Footer;
